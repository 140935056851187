<template>
    <modal ref="modalCalificacion" titulo="Califica Pedido" no-aceptar adicional="Calificar" @adicional="calificarPedido">
        <div class="row mx-0 mb-3 j-center">
            <div class="col-10">
                <div v-for="calificacion in itemsCalificacion" :key="calificacion.id" class="row mx-0 mb-4">
                    <p class="col-12 f-15 text-black">
                        {{ calificacion.nombre }}
                    </p>
                    <el-rate v-model="calificacion.calificacion" class="ml-3 mt-1" :colors="['#FF9D32', '#FF9D32', '#FF9D32']" void-color="#637381" />
                </div>
                <small class="text-black pl-3">
                    Deja tu comentario
                </small>
                <el-input v-model="comentario" type="textarea" :rows="4" class="w-100" />
            </div>
        </div>
    </modal>
</template>

<script>
import Calificar from '~/services/pedidos/calificarPedidos'
export default {
    data(){
        return{
            puntualidad: null,
            presentacion: null,
            comentario: '',
            calificacion: null,
            idPedido: null,
            itemsCalificacion: [],
            pedido: {
                id: null,
                calificacion: null,
                calificacion_comentario: null
            }
        }
    },
    methods:  {
        toggle(item){
            this.idPedido = item
            this.comentario = null,
            this.listarOpciones()
            this.$refs.modalCalificacion.toggle();
        },
        async calificarPedido(){
            try {
                const payload = {
                    calificaciones : this.itemsCalificacion,
                    comentario : this.comentario,
                    idPedido : this.idPedido
                }
                const { data } = await Calificar.calificarPedido(payload);
                this.$refs.modalCalificacion.toggle();
                this.notificacion('Éxito', data.mensaje ,'success');
                this.$emit('calificado', this.idPedido)
            
            } catch (e){
                this.errorCatch(e)
            }
        },
        async listarOpciones(){
            try {
                const { data } = await Calificar.getOpcionesCalificacion();
                this.itemsCalificacion = data.opcionesCalificacion;
            } catch (e){
                this.errorCatch(e)
            }
        }
    }
}
</script>