import axios from 'axios';

const API = 'cliente/pedidos-calificar';

const ENDPOINTS = {
    calificarPedido(payload){
        return axios.put(`${API}`, payload)
    },
    getOpcionesCalificacion(){
        return axios(`${API}/lista-opciones`)
    },
};

export default ENDPOINTS;